@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&family=Work+Sans:wght@300;400;500;600;700&display=swap");
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  cursor: default;
}

img {
  max-width: 100%;
}

.form-group {
  margin-bottom: 0;
}

/* custom scroll */

::-webkit-scrollbar {
  width: 8px;
  display: block;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #10732c;
}

/* modal */
.modal-backdrop {
  display: none;
}

.text-primary {
  color: #10732c !important;
}

/* #download img{
  border-radius:50%;
} */

.swal2-actions button {
  margin: 0 10px;
}

.dropdown-menu .dropdown-item:hover,
.dropdown-menu .dropdown-item:focus,
.dropdown-menu a:hover,
.dropdown-menu a:focus,
.dropdown-menu a:active {
  background-color: #10732c !important;
  color: #ffffff !important;
}

.dropdown-menu .dropdown-item:hover .text-success,
.dropdown-menu .dropdown-item:hover .text-danger {
  color: #fff !important;
}

.dropdown-menu .dropdown-item .text-success:hover,
.dropdown-menu .dropdown-item .text-success:focus,
.dropdown-menu a:hover,
.dropdown-menu a:focus,
.dropdown-menu a:active {
  background-color: #10732c !important;
  color: #ffffff !important;
}

.dropdown-menu .dropdown-item .text-danger:hover,
.dropdown-menu .dropdown-item .text-danger:focus,
.dropdown-menu a:hover,
.dropdown-menu a:focus,
.dropdown-menu a:active {
  background-color: #10732c !important;
  color: #ffffff !important;
}

.ngx-pagination .current {
  background: #10732c !important;
  border: 0 !important;
}

.img-wrap {
  width: 50px;
  height: 50px;
  border-radius: 50% !important;
  overflow: hidden !important;
}

.table-responsive {
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.dropdown-menu {
  cursor: pointer !important;
}

.status-select-wrap {
  margin: 10px auto 0;
  width: 150px !important;
}

.status-select-wrap:focus,
.status-select-wrap:focus-visible {
  outline: none;
  border: 0;
  box-shadow: none !important;
}

.table td,
.table th {
  padding: 12px 8px;
}
.table thead th:first-child,
.table tbody td:first-child {
  text-align: left;
}

.table thead th,
.table tbody td {
  text-align: center;
  margin: 0;
}

.backup_btn {
  color: #10732c;
  padding: 10px 0;
  display: inline-block;
}

#download p {
  margin: 0;
}

.iti {
  display: block !important;
  /* margin-bottom: 20px; */
}

ngx-intl-tel-input input {
  height: 40px;
  margin-bottom: 20px;
  padding: 0.375rem 0.75rem;
  border-style: solid;
  border-width: 1px;
  border-color: #c7cace;
  border-radius: 4px;
  font-size: 18px;
}
/* .navbar.navbar-absolute {
  z-index: -1;
} */

.navbar.navbar-absolute {
  z-index: 999 !important;
}

.form-control:focus {
  box-shadow: unset !important;
}

.actionModal .mat-dialog-container {
  padding: 0 !important;
  border-radius: 10px !important;
}

.close {
  color: #fff !important;
}

.dropdown-menu .dropdown-item,
.dropdown-menu li > a {
  padding-left: 10px !important;
}

button:hover {
  color: unset !important;
}
.btn.btn-primary:focus,
.btn.btn-primary.focus,
.btn.btn-primary:hover {
  color: #fff !important;
}

.backup_btn {
  cursor: pointer;
}

.para {
  text-transform: capitalize;
}
.user_name {
  text-transform: capitalize;
}
